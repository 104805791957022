var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-card',{staticClass:"col-12 col-xl-6"},[_c('b-card-text',[_c('validation-observer',{ref:"submitForm"},[_c('b-overlay',{attrs:{"show":_vm.playLoad,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm","no-wrap":""}}),_c('b-form-group',{staticClass:"text-center",attrs:{"description":"250 x 250"}},[_c('b-avatar',{attrs:{"src":_vm.imageURL,"size":"250","variant":"light-primary"}})],1),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"รูปโปรไฟล์","label-cols-md":"4"}},[_c('b-form-file',{attrs:{"accept":"image/*","type":"file","placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop image here..."},on:{"input":function($event){return _vm.imageConvertUrl(_vm.addAccount.imageProfile)},"change":_vm.handleFileUpload},model:{value:(_vm.addAccount.imageProfile),callback:function ($$v) {_vm.$set(_vm.addAccount, "imageProfile", $$v)},expression:"addAccount.imageProfile"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ชื่อผู้ใช้งาน (Username)","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"ชื่อผู้ใช้งาน (Username)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","state":errors.length > 0 ? false : null,"placeholder":"ชื่อผู้ใช้งาน (Username)"},model:{value:(_vm.addAccount.username),callback:function ($$v) {_vm.$set(_vm.addAccount, "username", $$v)},expression:"addAccount.username"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"เบอร์โทร","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"เบอร์โทร","rules":"required|integer|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"เบอร์โทร"},model:{value:(_vm.addAccount.phone),callback:function ($$v) {_vm.$set(_vm.addAccount, "phone", $$v)},expression:"addAccount.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"บ้านเลขที่","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"บ้านเลขที่","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"บ้านเลขที่"},model:{value:(_vm.addAccount.houseNo),callback:function ($$v) {_vm.$set(_vm.addAccount, "houseNo", $$v)},expression:"addAccount.houseNo"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"ที่อยู่","label-cols-md":"4"}},[_c('validation-provider',{attrs:{"name":"ที่อยู่","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ที่อยู่","rows":"3"},model:{value:(_vm.addAccount.address),callback:function ($$v) {_vm.$set(_vm.addAccount, "address", $$v)},expression:"addAccount.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"tag","label-cols-md":"4"}},[_c('b-form-tags',{staticClass:"mb-2",model:{value:(_vm.addAccount.tag),callback:function ($$v) {_vm.$set(_vm.addAccount, "tag", $$v)},expression:"addAccount.tag"}})],1)],1),_c('b-col',{attrs:{"offset-md":"4"}},[_c('b-button',{staticClass:"mr-1",attrs:{"type":"submit","variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm()}}},[_vm._v(" บันทึก ")])],1)],1)],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }