<template>
  <div>
    <div class="d-flex justify-content-center">
      <b-card class="col-12 col-xl-6">
        <b-card-text>
          <validation-observer ref="submitForm">
            <b-overlay :show="playLoad" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm" no-wrap />
            <b-form-group class="text-center" description="250 x 250">
              <!-- <b-img :src="imageConvertUrl(addAccount.imageProfile)" rounded="circle" style="height: 250px; width: 250px" thumbnail fluid /> -->

              <b-avatar :src="imageURL" size="250" variant="light-primary" />
            </b-form-group>
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="รูปโปรไฟล์" label-cols-md="4">
                    <!--  @input="imageConvertUrl(addAccount.imageProfile)" -->
                    <b-form-file
                      v-model="addAccount.imageProfile"
                      @input="imageConvertUrl(addAccount.imageProfile)"
                      @change="handleFileUpload"
                      accept="image/*"
                      type="file"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop image here..."
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ชื่อผู้ใช้งาน (Username)" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="ชื่อผู้ใช้งาน (Username)" rules="required">
                      <b-form-input disabled v-model="addAccount.username" :state="errors.length > 0 ? false : null" placeholder="ชื่อผู้ใช้งาน (Username)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- <b-col cols="12">
                  <b-form-group label="รหัสผ่านใหม่ (New Password)" label-cols-md="4" description="รหัสผ่าน อย่างน้อย 8 ตัว ">
                    <validation-provider #default="{ errors }" name="รหัสผ่านใหม่ (New Password)" rules="min:8">
                      <b-input-group>
                        <b-form-input v-model="addAccount.password" :type="passwordFieldType" :state="errors.length > 0 ? false : null" placeholder="รหัสผ่าน (Password)" />
                        <b-input-group-append is-text>
                          <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility" />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col> -->
                <b-col cols="12">
                  <b-form-group label="เบอร์โทร" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="เบอร์โทร" rules="required|integer|min:10">
                      <b-form-input v-model="addAccount.phone" :state="errors.length > 0 ? false : null" placeholder="เบอร์โทร" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="บ้านเลขที่" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="บ้านเลขที่" rules="required">
                      <b-form-input v-model="addAccount.houseNo" :state="errors.length > 0 ? false : null" placeholder="บ้านเลขที่" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="ที่อยู่" label-cols-md="4">
                    <validation-provider #default="{ errors }" name="ที่อยู่" rules="required">
                      <b-form-textarea v-model="addAccount.address" :state="errors.length > 0 ? false : null" placeholder="ที่อยู่" rows="3" />
                      <!-- <b-form-input v-model="addAccount.nameMeet" :state="errors.length > 0 ? false : null" placeholder="ที่อยู่" /> -->
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="tag" label-cols-md="4">
                    <b-form-tags v-model="addAccount.tag" class="mb-2" />
                  </b-form-group>
                </b-col>

                <!-- submit and reset -->
                <b-col offset-md="4">
                  <b-button @click.prevent="submitForm()" type="submit" variant="primary" class="mr-1"> บันทึก </b-button>
                  <!-- <b-button @click="resetForm()" variant="outline-secondary"> รีเซ็ต </b-button> -->
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver, localize } from 'vee-validate';
  import { required, alphaDash, min, integer } from '@validations';
  import { togglePasswordVisibility } from '@core/mixins/ui/forms';
  import API from '@/connect/config';
  import axios from 'axios';

  // import dayjs from 'dayjs';
  // import 'dayjs/locale/th';
  // import utc from 'dayjs/plugin/utc';
  // import timezone from 'dayjs/plugin/timezone';
  // dayjs.extend(utc);
  // dayjs.extend(timezone);
  // const buddhistEra = require('dayjs/plugin/buddhistEra');
  // dayjs.extend(buddhistEra);

  export default {
    name: 'user_regis', //ลงทะเบียนผู้ใช้งาน
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        // ? VeeValidate
        required,
        alphaDash,
        min,
        integer,
        //? locale
        locale: 'th',
        // ? สร้างนัดหมาย
        imageURL: null,
        _imageURL: null,
        addAccount: {
          imageProfile: null, //รูปโปรไฟล์
          username: '', //username
          password: '', //password
          phone: '', //เบอร์โทร
          houseNo: '', //บ้านเลขที่
          address: '', //ที่อยู่
          tag: [], //tag
          userId: '',
          rule: 'member',
          editMode: true,
          liffId: '',
          lineId: '',
          lineName: '',
        },
        // ? loading
        playLoad: false,
      };
    },
    mixins: [togglePasswordVisibility],
    computed: {
      passwordToggleIcon() {
        return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
      },
    },

    created() {
      // ? Validat thai
      localize(this.locale);
      this.setProfile();
    },

    methods: {
      async handleFileUpload(event) {
        const file = event.target.files;
        if (file.length == 0) return;

        Array.from(file).forEach((file) => {
          console.log(file);
          // Create an image element
          const img = new Image();
          const reader = new FileReader();
          reader.onload = (e) => {
            img.src = e.target.result;
          };
          reader.readAsDataURL(file);
          img.onload = () => {
            // Create a canvas element
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set the canvas size
            const MAX_WIDTH = 600; // Set your desired max width
            const MAX_HEIGHT = 600; // Set your desired max height
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
              }
            } else {
              if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);

            canvas.toBlob(
              (blob) => {
                const resizedFile = new File([blob], file.name, { type: 'image/jpeg' }, 1);
                // console.log(resizedFile)
                this.addAccount.imageProfile = resizedFile;
              },
              'image/jpeg',
              1
            );
          };
        });
      },
      async setProfile() {
        this.playLoad = true;
        const { imageProfile, username, phone, houseNo, address, tag } = JSON.parse(localStorage.getItem('profile_visitor'));
        let image = null;
        if (imageProfile[0]) {
          await this.$store.dispatch('getImage', imageProfile[0]).then(async (el) => {
            this.imageConvertUrl(el);
            image = el;
          });
        }
        this.addAccount = {
          imageProfile: image, //รูปโปรไฟล์
          username: username.split('@')[0], //username
          password: '', //password
          phone: phone, //เบอร์โทร
          houseNo: houseNo, //บ้านเลขที่
          address: address, //ที่อยู่
          tag: tag,
        };
        // console.log(this.addAccount);
        this.playLoad = false;
      },
      async submitForm() {
        this.playLoad = true;
        const { userId, liffId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
        const { line_name, line_userId } = JSON.parse(localStorage.getItem('info_line'));
        const { username } = JSON.parse(localStorage.getItem('profile_visitor'));
        if (userId) {
          this.addAccount.userId = userId;
          this.addAccount.liffId = liffId;
          this.addAccount.lineName = line_name;
          this.addAccount.lineId = line_userId;
          this.addAccount['edit_username_regis'] = `${this.addAccount.username}@${username.split('@')[1]}`;
          console.log(this.addAccount);
        }

        this.$refs.submitForm.validate().then(async (success) => {
          if (success) {
            console.log(this.addAccount);
            const { imageProfile, edit_username_regis, password, phone, houseNo, address, tag, userId, rule, editMode, liffId, lineId, lineName } = this.addAccount;
            const _data = new FormData();
            _data.append('userId', userId);
            _data.append('imageProfile', imageProfile);
            _data.append('username', edit_username_regis);
            //_data.append('password', password);
            _data.append('phone', phone);
            _data.append('houseNo', houseNo);
            _data.append('address', address);

            if (tag != null) {
              tag.forEach((el) => {
                _data.append('tag[]', el);
              });
            }
            _data.append('rule', 'member');
            _data.append('editMode', true);
            _data.append('liffId', liffId);
            _data.append('lineId', lineId);
            _data.append('lineName', lineName);

            axios
              .put(`${API}addAccount`, _data)
              .then(async (res) => {
                console.log(res);

                this.$swal({
                  title: 'แก้ไขผู้ใช้งาน',
                  text: 'กดปิดเบราว์เซอร์ แล้วเข้าจาก rich menu ใหม่',
                  type: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                  },
                });

                this.$store.commit('setToastMessage', {
                  message: `${res.data.message}`,
                  title: 'แก้ไขผู้ใช้งาน',
                  variant: 'primary',
                });
              })
              .catch(async (error) => {
                console.log(error.response);
                this.$store.commit('setToastMessage', {
                  message: `${error.response.data.message}`,
                  title: 'แก้ไขผู้ใช้งาน',
                  variant: 'danger',
                });
              });

            this.playLoad = false;
          } else {
            // re-validate to re-generate the messages.
            this.$refs.submitForm.validate();

            this.playLoad = false;
          }
        });
      },
      resetForm() {
        this.setProfile();
        this.$nextTick(() => {
          //this.$refs.submitForm.reset();
        });
      },
      async imageConvertUrl(image) {
        if (image) {
          const url = window.URL.createObjectURL(await image);
          this.imageURL = url;
        }
      },
    },
  };
</script>

<style></style>
